import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bienvenida from "./componentes/Bienvenida.js";
import About from "./componentes/about.js";
import Productos from "./componentes/Productos.js";
import BestContactCenter from "./componentes/BestContactCenter.js";
import BestCallCenter from "./componentes/BestCallCenter.js";
import BestDid from "./componentes/BestDid.js";
import Pago from "./componentes/Pago.js";
import BestPbx from "./componentes/BestPbx.js";
import Masivos from "./componentes/Masivos.js";
import BestIptrunk from "./componentes/BestIptrunk.js";
import Gracias from "./componentes/gracias.js";
import Enviado from "./componentes/Enviado.js";
import Login from "./componentes/Login.js";

import FuncionalidadesGenerales from "./componentes/FuncionalidadesGenerales";
import FuncionalidadesRetail from "./componentes/FuncionalidadesRetail";
import FuncionalidadesFinancieros from "./componentes/FuncionalidadesFinancieros";
import FuncionalidadesSeguros from "./componentes/FuncionalidadesSeguros";
import FuncionalidadesSalud from "./componentes/FuncionalidadesSalud";
import Politicas from "./componentes/Politicas";
import FuncionalidadesTurismo from "./componentes/FuncionalidadesTurismo";
import FuncionalidadesSoporteTecnico from "./componentes/FuncionalidadesSoporteTecnico";
import {ProtectedRoute}  from "./componentes/ProtectedRoute.js";
import Registro from "./componentes/Registro.js"
import Reset from "./componentes/Reset.js"
import Tutoriales from "./componentes/Tutoriales.js"
import Contacto from "./componentes/Contacto.js"
import {AuthProvider} from './context/authContext.js'

function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Bienvenida />} />
          <Route path="/about" element={<About />} />
          <Route path="/productos" element={<Productos />} />
          <Route
            path="/productos/bestcontactcenter"
            element={<BestContactCenter />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesGenerales"
            element={<FuncionalidadesGenerales />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesRetail"
            element={<FuncionalidadesRetail />}
          />
          
          
          <Route
            path="/feedback"
            element={<Gracias />}
          />
           <Route
            path="/enviado"
            element={<Enviado />}
          />
           <Route
            path="/politicas"
            element={<Politicas />}
          />
          <Route
            path="/pago"
            element={<Pago/>}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesFinancieros"
            element={<FuncionalidadesFinancieros />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesSeguros"
            element={<FuncionalidadesSeguros />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesSalud"
            element={<FuncionalidadesSalud />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesTurismo"
            element={<FuncionalidadesTurismo />}
          />
          <Route
            path="/productos/bestcontactcenter/funcionalidadesSoporteTecnico"
            element={<FuncionalidadesSoporteTecnico />}
          />
          <Route
            path="/productos/bestcallcenter"
            element={<BestCallCenter />}
          />
          <Route path="/productos/bestdid" element={<BestDid />} />
          <Route path="/productos/bestiptrunk" element={<BestIptrunk />} />
          <Route path="/productos/masivos" element={<Masivos />} />
          <Route path="/productos/bestpbx" element={<BestPbx />} />
          <Route path="/login" element={<Login />} />
          <Route path="/tutoriales" element={<ProtectedRoute><Tutoriales /></ProtectedRoute>} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/contacto" element={<Contacto />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
