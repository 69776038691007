import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../context/authContext";
import facebook from "../elementos/facebook.svg"
import instagram from "../elementos/instagram.svg"
import twitter from "../elementos/twitter.svg"
import whatsapp from "../elementos/whatsapp.svg"
import envelope from "../elementos/envelope.svg"
import "../App.css";
export default function 
() {
  const { user, logOut, loading, current } = useAuth();
  const handleLogOut = async () => {
    await logOut();
  };
  const [state, setState] = useState(false)
  const handle = () =>{
    setState(!state)
    toogle()
  }
  const toogle = () =>{
    if(state){
      var uno = document.getElementById("men");
      uno.style.display = "block";
      uno.style.transition = "display 2s ease";
    }else{
      var uno = document.getElementById("men");
      uno.style.display = "none";
      uno.style.transition = "display 2s ease";
    }
  }  
    
  
 
  return (
    <div>
    
      <div className="menu-lateralS">
        <div className="contenedorMenuLateralS">
        <a href="https://www.facebook.com/bestvoiper/" target="_blank">
        <img src={facebook} className="red"/>
        </a>
        <a href="https://www.instagram.com/bestvoiper/?hl=es-la" target="_blank">
        <img src={instagram} className="red"/>
        </a>
        <a href="https://twitter.com/bestvoiper" target="_blank">
        <img src={twitter} className="red"/>
        </a>
        <a href="https://api.whatsapp.com/send?phone=573124560009&text=Me%20gustan%20los%20productos%20ofrecidos,%20requiero%20mas%20informacion." target="_blank">
        <img src={whatsapp} className="red"/>
        </a>
       <Link to="/contacto"> 
       <img src={envelope} className="red"/>
      </Link>
        </div>
      </div>
    </div>
  )
}
