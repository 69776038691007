import React from 'react'
import "../App.css";
import {Link} from 'react-router-dom'
export default function AvisoCookies() {

    const close = () =>{
        let bandera = document.getElementById("contenedor")
        bandera.style.display = "none"
    }
  return (
    <div id="contenedor" className="contenedorAviso">
      <div className='contenedorContenido'>
       <p>Solicitamos su permiso para obtener datos estadísticos de su navegación en esta web. Si continúa navegando consideramos que acepta el uso de cookies.</p>
     <div className='cajaBotones'>
     <button onClick={close} className="botones">OK</button>
     <Link to="/politicas">
     <button  className="botones">Más Información</button>
     </Link>
     </div>
      </div>


    </div>
  )
}
