import React, {useEffect} from "react";
import { useAuth } from "../context/authContext";
import Navegacion from "./Navegacion.js";
import video from "../elementos/bestvideo.mp4";
import { getAuth, onAuthStateChanged } from "firebase/auth";
export default function Tutoriales() {
  const { user, logOut, loading, current } = useAuth();
  useEffect(() => {
    

  if (!current) {
    window.location.href = "/login";
    
    
  } 

  }, [])
  
  
  const handleLogOut = async () => {
    await logOut();
  };

  if (loading) return <h1>Loading...</h1>;
  return (
    <div>
      <Navegacion />
      <p className="tituloTutoriales">TUTORIALES</p>
      <p className="user">Bienvenido, {user.displayName || user.email}</p>
      <div className="tutoriales">
        <div className="itemTutorial">
          <div>
            <video controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div>
            <p>
              <b>Tutorial:</b> Entrar a la plataforma
            </p>
          </div>
        </div>
        <div className="itemTutorial">
          <div>
            <video controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div>
            <p>
              <b>Tutorial:</b> Entrar a la plataforma
            </p>
          </div>
        </div>
        <div className="itemTutorial">
          <div>
            <video controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div>
            <p>
              <b>Tutorial:</b> Entrar a la plataforma
            </p>
          </div>
        </div>
        <div className="itemTutorial">
          <div>
            <video controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div>
            <p>
              <b>Tutorial:</b> Entrar a la plataforma
            </p>
          </div>
        </div>

      </div>
      
    </div>
  );
}
