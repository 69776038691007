import React, { useContext, useEffect, useState } from 'react'
import {createContext} from 'react'
import {createUserWithEmailAndPassword, onAuthStateChanged, signOut,
GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail} from 'firebase/auth'
import {signInWithEmailAndPassword} from 'firebase/auth'
import {auth} from '../componentes/firebase.js'
import { useNavigate } from 'react-router-dom'
export const authContext = createContext()

export const useAuth =() =>{
  const context = useContext(authContext)
  return context;
}

export function AuthProvider ({children}) {
  const[user, setUser] = useState(null)
  const[loading, setLoading] = useState(true)

      const signUp = (email, pass) =>
        createUserWithEmailAndPassword(auth,email, pass)
      
        const signIn = (email, pass) =>
        signInWithEmailAndPassword(auth,email, pass)

        const logOut = () =>
        signOut(auth)

        const current = auth.currentUser;

        const loginWithGoogle = () =>{
           const googleProvider =  new GoogleAuthProvider()
           return signInWithPopup(auth, googleProvider)

       
        }
        const resetPassword = (email) => {
          sendPasswordResetEmail(auth, email)
        }

        useEffect(()=>{
      const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
         setUser(currentUser)
         setLoading(false)
       });
       return () => unsubscribe();
        },[]);
      
      return (<authContext.Provider value={{ current, signUp, signIn, user, logOut, loading, loginWithGoogle,
      resetPassword}}>
        {children}
        </authContext.Provider>
      );
}