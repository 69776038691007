import React, { useState } from "react";
import Slider from "react-slick";
import "../App.css";
import slider1 from "../elementos/FUNCIONALIDADRETAIL1.svg";
import slider2 from "../elementos/FUNCIONALIDADRETAIL2.svg";
import slider3 from "../elementos/FUNCIONALIDADRETAIL3.svg";
import slider4 from "../elementos/FUNCIONALIDADRETAIL4.svg";
import slider5 from "../elementos/FUNCIONALIDADRETAIL5.svg";
import slider6 from "../elementos/FUNCIONALIDADRETAIL6.svg";
import slider7 from "../elementos/FUNCIONALIDADRETAIL8.svg";
import slider8 from "../elementos/FUNCIONALIDADRETAIL9.svg";
import slider9 from "../elementos/FUNCIONALIDADRETAIL7.svg";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
const images = [slider1, slider2, slider3, slider4, slider5, slider6,slider7, slider8, slider9];

export default function FuncionalidadesRetail() {
    
   
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  return (
    <div className="tituloFinanciero">
        <p>FUNCIONALIDADES</p>
        <p>RETAIL</p>
<div className="App">
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className={idx === imageIndex ? "slide activeSlide" : "slide"}
              >
                <img src={img} alt={img} />
              </div>
            ))}
          </Slider>
        </div>

    </div>
  )
}
