import React, {useEffect} from "react";
import video from "../elementos/bestvideo.mp4";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDiagramProject,
  faCircleInfo,
  faUser,
  faAnglesDown
} from "@fortawesome/free-solid-svg-icons";
import Navegacion from './Navegacion.js'
import RedesSociales from "./RedesSociales.js";
import AvisoCookies from "./AvisoCookies";


export default function () {
  const navigate = useNavigate()
  setTimeout(function(){
    navigate("/productos")
      },14000);
  return (
    <div className="bienvenida">
      
      <Navegacion/>
    
      <RedesSociales/>
    
      <AvisoCookies/>
      <video autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </video>
      
     
    </div>
  );
}
