import React, { useState } from "react";
import robot from "../elementos/robot.svg";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales.js";
import {useNavigate, Link} from 'react-router-dom'
import {useAuth} from '../context/authContext'
export default function Reset() {
  const {resetPassword} = useAuth()
  const navigate = useNavigate()
  const [user, setUser] = useState({
    email: ""
  });
  
  const [error, setError] = useState()
  const handle = (e) =>{
    let name = (e.target.name.trim()).replace(/[\/\\\<\>]+/g,'');
    let value = (e.target.value.trim()).replace(/[\/\\\<\>]+/g,'');
    setUser({...user,[name]: value})
    
  }

  const send = async(e) =>{
    e.preventDefault()
    if(!user.email) return setError("Por favor ingresa tu correo")
    try {
     await  resetPassword(user.email)
     setError("Verifica tu correo electronico")
    } catch (error) {
      console.log(error)
    }
  
  

  }
  return (
    <div>
      <RedesSociales/>
      <div className="formulario">
        <Navegacion />
        
        <img src={robot} />
        <form onSubmit={send}>
        <p className="tituloForm">RECUPERAR CONTRASEÑA</p>
          <input onChange={handle} type="email" placeholder="correo" name="email" />
          {error ?(<p className="error">{error}</p>): null}
          <input type="submit" value="ENVIAR" name="enviar" />
        
        <Link className="noUnderline" to="/login"> 
          <p className="registro">Iniciar Sesión</p>
          </Link>
          </form>
      </div>
    </div>
  );
}
