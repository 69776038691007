import React, { Component} from "react";
import robot from "../elementos/contacto.svg";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales.js";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/authContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default class Email extends Component {
  
   nombres = React.createRef()
   telefono = React.createRef()
  email = React.createRef()
   mensaje = React.createRef()


   state = {
       nombres: "",
       email: "",
       telefono: "",
       mensaje: "",
       enviado: ""
      
   }

   
   
   notify = () =>
   toast(
     <div className="toaster" >
       <p>!MENSAJE ENVIADO!</p>
       <img src={robot} alt="imagen" />
     </div>
   );

  
   comprobarCambios = () => {
       var nombres = ((this.nombres.current.value).trim()).replace(/[\/\\\<\>]+/g,'');
       var email = ((this.email.current.value).trim()).replace(/[\/\\\<\>]+/g,'');
       var telefono = ((this.telefono.current.value).trim()).replace(/[\/\\\<\>]+/g,'');
       var mensaje = ((this.mensaje.current.value).trim()).replace(/[\/\\\<\>]+/g,'');
       this.setState({
           nombres: nombres,
           email: email,
           telefono: telefono,
           mensaje:mensaje
          
       })
   
     
   }
    


   constructor () {
       super();
       this.enviarEmail = this.enviarEmail.bind(this);
       this.notify = this.notify.bind(this);
    

   
   }



   async enviarEmail(e){
  
   e.preventDefault();
   
   const{nombres,email, telefono, mensaje} = this.state;
   
   this.setState({enviado:"Mensaje enviado"})
  
   const form = await axios.post("/api/post",{
       nombres,
       email,
       telefono,
       mensaje
     
   },   
   window.location.href = "/enviado",
   document.getElementById("nombres").value = "",
   document.getElementById("email").value = "",
   document.getElementById("telefono").value = "",
   document.getElementById("mensaje").value = "",
   
    )
  
   
   }

   
   render() {
       return (
        <div>
          <RedesSociales/>
        <div className="formularioContacto">
          <Navegacion />
          
          <img src={robot} />
         
  
          <form onSubmit={this.enviarEmail} id="formulario">
            <p className="tituloForm">CONTÁCTANOS</p>
          
            <input
              id="nombres"
              type="text"
              placeholder="Nombre y Apellido"
              name="nombres"
              onChange={this.comprobarCambios}
              ref={this.nombres}
              required
            />
            <input
            id="email"
              onChange={this.comprobarCambios}
              ref={this.email}
              type="email"
              placeholder="Correo"
              name="email"
              required
            />
             <input
             id="telefono"
             onChange={this.comprobarCambios}
             ref={this.telefono}
              type="number"
              placeholder="Teléfono de Contacto"
              name="telefono"
              required
            />
             <textarea
             id="mensaje"
            placeholder="¿Cómo podemos ayudarte?(opcional)"
             name="mensaje"
             onChange={this.comprobarCambios}
              ref={this.mensaje}
          />
            <input type="submit" value="ENVIAR MENSAJE" name="enviar" />
            {this.state.enviado != ""? (
            <div className="enviado">
              <p>{this.state.enviado}</p>
            </div>
             ): null}
              <p className="registro">Teléfonos: (+57)3330333014 || (+57)312 456 0009</p>
            
            
              <p className="olvido">info@bestvoiper.com.co</p>
            
          </form>
        </div>
       
        <ToastContainer
      position="bottom-center"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  
      </div>
  
       )
   }
}
