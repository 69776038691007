import React,{useEffect} from 'react'
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaArrowRight, FaArrowLeft,FaShoppingCart, FaPhone } from "react-icons/fa";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";

export default function () {
    let precioFinal2 = localStorage.getItem("precio");
    let cantidad = localStorage.getItem("cantidad");
    let producto = localStorage.getItem("producto");
     let item = localStorage.getItem("item");
    
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.epayco.co/checkout.js";
        script.async = true;
    
        document.body.appendChild(script);
    }, [])
    
  return (
    <div className='bigContainer'>
         <Navegacion />
         <RedesSociales/>

<p className="adquiere">ADQUIERE  {producto} AHORA MISMO</p>
    
     
     <p className="adquiere1"><b>Cantidad:</b> {cantidad} {item}</p>
     <p className="adquiere1"><b>Total: </b> $ {precioFinal2} COP</p>
      <div className="botonPago">

       
      <form className="checkout">
        <div className="inlineForm">
     
        <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="4b3a43650f5eebfc4f4e306a400b069c"
            data-epayco-amount={precioFinal2}
            data-epayco-name={producto}
            data-epayco-description={producto}
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-button="https://multimedia.epayco.co/epayco-landing/btns/boton-epayco-fondo-oscuro1.png
            "
          git
            data-epayco-external="false"
            data-epayco-response="http://www.bestvoiper.com.co/feedback"
            data-epayco-confirmation="http://www.bestvoiper.com.co/feedback"
            data-epayco-methodconfirmation="get">
        </script>
        <FaShoppingCart className="iconS"/>
        </div>
    </form>
      </div>

    </div>
  )
}
