import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDiagramProject,
  faCircleInfo,
  faUser,
  faAnglesDown,
  faHome,
  faShoppingCart,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import "../App.css";
export default function Menu() {
    const [state, setState] = useState(false)
  const handle = () =>{
    setState(!state)
    toogle()
  }
  const toogle = () =>{
    if(state){
      var uno = document.getElementById("men");
      uno.style.display = "block";
      uno.style.transition = "display 2s ease";
    }else{
      var uno = document.getElementById("men");
      uno.style.display = "none";
      uno.style.transition = "display 2s ease";
    }
  }  
  return (
    <div className='menu'>
      <div className='contenedorMenu'>
      <div className="menu-principal">
        <div className="contenedorMenuPrincipal">
          <FontAwesomeIcon onClick={handle}  icon={faXmark} className="hamburguer"/>
        </div>
      </div>
        <ul>
            <p className='titleMenu'>MENU PRINCIPAL</p>
            <Link className='noUnderline' to="/about">
            <li><p>Sobre Nosotros</p></li>
            </Link>
            <Link className='noUnderline' to="/productos">
            <li><p>Nuestros Productos</p></li>
            </Link>
            <Link className='noUnderline' to="/contacto">
            <li><p>Contacto</p></li>
            </Link>
            <Link className='noUnderline' to="/login">
            <li><p>Iniciar Sesión</p></li>
            </Link>
            

        </ul>
        </div>
    </div>
  )
}
