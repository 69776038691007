import React, { useState } from "react";
import "../App.css";
import world from "../elementos/masivo1.json";
import Slider from "react-slick";
import intro from "../elementos/sms_video.mp4";
import flash from "../elementos/flash.png";
import standar from "../elementos/standard.png";
import doble from "../elementos/dobleVia.png";
import transaccional from "../elementos/transaccionales.png";
import slider1 from "../elementos/MASIVOSF1.svg";
import slider2 from "../elementos/MASIVOSF2.svg";
import slider3 from "../elementos/MASIVOSF3.svg";
import slider4 from "../elementos/MASIVOSF4.svg";
import Contacto from "./Contacto.js";
import TITULO_MASIVOS from "../elementos/TITULO_SERVICIOS_MASIVOS.svg";
import funcionalidad1 from "../elementos/CARACTERISTICASMASIVOS1.svg";
import funcionalidad2 from "../elementos/CARACTERISTICASMASIVOS2.svg";
import funcionalidad3 from "../elementos/CARACTERISTICASMASIVOS3.svg";
import funcionalidad4 from "../elementos/CARACTERISTICASMASIVOS4.svg";
import funcionalidad5 from "../elementos/CARACTERISTICASMASIVOS5.svg";

import DiagramaDid from "../elementos/diagramadid.js";
import animation2 from "../elementos/mark.json";
import animation3 from "../elementos/247.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import { FaArrowRight, FaArrowLeft,FaShoppingCart } from "react-icons/fa";
const images = [slider1, slider2, slider3, slider4];

const images2 = [
  funcionalidad1,
  funcionalidad2,
  funcionalidad3,
  funcionalidad4,
  funcionalidad5,
];
export default function Masivos() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();
  let animationContainer3 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: world,
    });
    anim.setSpeed(1);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animation2,
    });
    anim2.setSpeed(1);
    const anim3 = lottie.loadAnimation({
      container: animationContainer3.current,
      animationData: animation3,
    });
    anim2.setSpeed(1);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);
  const [imageIndex2, setImageIndex2] = useState(0);
  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const settings2 = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex2(next),
  };

  return (
    <div className="bigContainer">
      <Navegacion />
     
      <div className="containerCall">
        <div className="tituloMasivos">
          <img src={TITULO_MASIVOS} />
        </div>
        <div className="videoCallcenter">
          <div className="conjunto_circle">
            <div className="circle"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
          </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
        </div>
      </div>
     <div className="Marco">
     
     <div className="geolocalizacion">
        <div className="containerP">
          <p className="contenidoM">
            Hacemos que el marketing en SMS sea más inteligente, personalizado y
            automatizado.
          </p>
          <div className="imagenCallM" ref={animationContainer2} />
        </div>
      </div>

      <div className="Pagos">
        <div className="containerP">
        <div className="imagenCallM" ref={animationContainer} />
          <p className="contenidoM">
          Nuestra plataforma podrá enviar información de forma masiva,
            mejorando la experiencia de contacto con sus clientes.
          </p>
        </div>
      </div>
      

     

      <div className="Pagos2">
        <div className="containerP">
        <p className="contenidoM2">
            Llega a más personas, automatiza tus conversaciones y obtén más
            ventas. Ofrecemos soporte 24/7.Somos la plataforma de servicios
            masivos que necesita tu negocio para crecer.
          </p>
          <div className="imagenCallM" ref={animationContainer3} />
        
        </div>
      </div>
      </div>

      <p className="tituloCaracteristicas">FUNCIONALIDADES</p>
      <div className="App">
      <Slider {...settings2}>
        {images2.map((img, idx) => (
          <div className={idx === imageIndex2 ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
      </div>
      <p className="tituloCaracteristicas">CARACTERISTICAS</p>
      <div className="App">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
      </div>
      <p className="tituloCaracteristicas">TIPOS DE MENSAJES</p>
      <div className="geolocalizacion">
        
        <p className="tituloPagos">MENSAJES FLASH</p>
        <div className="containerP">
          <img className="flash" src={flash} />
          <ul>
         <li> <p className="contenidoMf">
            Servicio por medio del cual <b>podrá enviar mensajes de forma masiva a sus clientes,
            y estos se verán reflejados en la pantalla principal inmediatamente sin importar la 
            aplicación que tengan abierta.</b> Da un 100% de prioridad a lo que quiere mostrar. 
          </p></li>
          <li><p className="contenidoMf">
            Se puede implementar como apoyo de contacto en campañas outbound de gestión de venta, recuperación
            de cartera o fidelización de cliente.
          </p></li>
          </ul>
          
        </div>
      </div>

      <div className="Pagos">
      <p className="tituloPagos">STANDARD</p>
        <div className="containerP">
      
       
          <p className="contenidoMf">
          Con este servicio no sólo enviara SMS masivos, sino que podrá
          <ul>
            <li><p className="contenidox"><b>Programar  sus envíos e integrar una URL para hacer estadisticas,
              ya que contamos con el servicio de landing page.</b></p></li>
              <li><p className="contenidox">Crear SMS de texto con URL a Whatsapp y saber si sus clientes interactuan
                con este.</p></li>
                <li><p className="contenidox">Integrar otros productos como: docs, landing page, retorno a whatsapp,
                  entre otros.</p></li>
          </ul>
          </p>
          <img className="standar" src={standar} />
        </div>
      </div>
      <div className="geolocalizacion">
      <p className="tituloPagos">MENSAJES DOBLE VÍA</p>
        <div className="containerP">
        <img className="doble" src={doble} />
          <p className="contenidoMf">
            Con este sistema sus clientes podrán responder a un mensaje de texto(sms)
            enviado previamente. En una herramienta muy <b>efectiva para confirmaciones 
            precisas y se aplica en campañas que requieren gesitón back office o procesos
            de logística</b> que incluyen procesos como:
            <ul>
              <li><p className="contenidox">Encuestas</p></li>
              <li><p className="contenidox">Acuerdos a cierres comerciales</p></li>
              <li><p className="contenidox">Actualización de datos</p></li>
              <li><p className="contenidox">Confirmación de disponibilidad</p></li>
              <li><p className="contenidox">Confirmación de entrega(Procesos logística)</p></li>
            </ul>
          </p>
          
        </div>
      </div>

      <div className="Pagos">
      <p className="tituloPagos">MENSAJES TRANSACCIONALES</p>
        <div className="containerP">
       <ul>
         <li>
          <p className="contenidoMf">
          Más conocidos como OTP(One Time Password), <b>son códigos númericos de un solo uso y para
          una sola transacción.</b> Los recibirá a través de un SMS en su teléfono móvil cada vez que quiera ejecutar cualquier
          operación o realizar cualquier petición a través de su banca a distancia.

          </p>
          </li>
          <li>
          <p className="contenidoMf">
          Es ideal para campañas bancarias que requieren transacciones con alto nivel de seguridad.
          
          </p></li>
          </ul>
          <img className="standar" src={transaccional} />
        </div>
      </div>
      <p className="adquiere">ADQUIERE SMS MASIVOS AHORA MISMO</p>
      <div className="botonPago">

     
      <form className="checkout">
        <div className="inlineForm">
     
        <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="4b3a43650f5eebfc4f4e306a400b069c"
            data-epayco-amount="300000"
            data-epayco-name="BestSmsMasivos"
            data-epayco-description="BestSmsMasivos"
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-button="https://multimedia.epayco.co/epayco-landing/btns/boton-epayco-fondo-oscuro1.png
            "
          
            data-epayco-external="false"
            data-epayco-response="http://www.bestvoiper.com.co/feedback"
            data-epayco-confirmation="http://www.bestvoiper.com.co/feedback"
            data-epayco-methodconfirmation="get">
        </script>
        <FaShoppingCart className="iconS"/>
        </div>
    </form>
      </div>
      <Contacto name="masivos"/>
    </div>
  );
}
