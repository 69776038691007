import React,{useState} from "react";
import "../App.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function DropDown() {
  const [first, setFirst] = useState(false)
  const [second, setSecond] = useState(false)
  const [third, setThird] = useState(false)
  const [fourth, setFourth] = useState(false)
  const [fifth, setFifth] = useState(false)
  const [sixth, setSixth] = useState(false)
  const [seven, setSeven] = useState(false)
  const toggle1 = () => {
    setFirst(!first)
    handle1()
  };
  const handle1 = () => {
    if(first){
      var uno = document.getElementById("response1");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response1");
      uno.style.display = "none";
    }
  };
  
  const toggle2 = () => {
    setSecond(!second)
    handle2()
  };
  const handle2 = () => {
    if(second){
      var uno = document.getElementById("response2");
      uno.style.display = "block";
      
    }else{
      var uno = document.getElementById("response2");
      uno.style.display = "none";
    }
  };
  
  const toggle3 = () => {
    setThird(!third)
    handle3()
  };
  const handle3 = () => {
    if(third){
      var uno = document.getElementById("response3");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response3");
      uno.style.display = "none";
    }
  };
  
  const toggle4 = () => {
    setFourth(!fourth)
    handle4()
  };
  const handle4 = () => {
    if(fourth){
      var uno = document.getElementById("response4");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response4");
      uno.style.display = "none";
    }
  };
  
  const toggle5 = () => {
    setFifth(!fifth)
    handle5()
  };
  const handle5 = () => {
    if(fifth){
      var uno = document.getElementById("response5");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response5");
      uno.style.display = "none";
    }
  };
  
  const toggle6 = () => {
    setSixth(!sixth)
    handle6()
  };
  const handle6 = () => {
    if(sixth){
      var uno = document.getElementById("response6");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response6");
      uno.style.display = "none";
    }
  };
  
  const toggle7 = () => {
    setSeven(!seven)
    handle7()
  };
  const handle7 = () => {
    if(seven){
      var uno = document.getElementById("response7");
      uno.style.display = "block";
    }else{
      var uno = document.getElementById("response7");
      uno.style.display = "none";
    }
  };
  
  return (
    <div>
      <div className="contenedorToogle">
        <div
          className="tituloToggle1"
          onClick={toggle1}
         
        >
          <p className="toggleTitulo">Discador Automático</p>
          <FontAwesomeIcon icon={faChevronDown} className="chevron" />
        </div>
        <div id="response1">
          <p>
            Marcador inteligente que optimiza el tiempo productivo del agente y
            aumenta la cantidad de contactos efectivos.
          </p>
        </div>

        <div>
          <div className="tituloToggle2" onClick={toggle2}>
            <p className="toggleTitulo">Grabación de llamadas</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response2">
            <p>
              Tanto salientes como entrantes, para auditoria y gestión de
              procesos.
            </p>
          </div>
        </div>
        <div>
          <div className="tituloToggle3" onClick={toggle3}>
            <p className="toggleTitulo">ACD</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response3">
            <p>
              Distribuye de forma inteligente las llamadas a los operadores
              según reglas configurables.
            </p>
          </div>
        </div>
        <div>
          <div className="tituloToggle4" onClick={toggle4}
         >
            <p className="toggleTitulo">API</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response4">
            <p>
              Integración vía webservices a bases de datos y sistemas de terceros.
            </p>
          </div>
        </div>
        <div>
          <div className="tituloToggle5" onClick={toggle5}
         >
            <p className="toggleTitulo">IVR</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response5">
            <p>
              Realiza la atención automática de las llamadas entrantes,
              permitiendo que el cliente autogestione sus consultas.
            </p>
          </div>
        </div>
        <div>
          <div className="tituloToggle6" onClick={toggle6}
         >
            <p className="toggleTitulo">Dashboard</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response6">
            <p>
              Facilita el monitorio en tiempo real de la gestión y el
              rendimiento de los agentes y colas de atención.
            </p>
          </div>
        </div>
        <div>
          <div className="tituloToggle7" onClick={toggle7}
         >
            <p className="toggleTitulo">CRM</p>
            <FontAwesomeIcon icon={faChevronDown} className="chevron" />
          </div>
          <div id="response7">
            <p>
              Gestión y administración de bases de datos, campañas y contactos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
