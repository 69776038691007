import React, { useState } from "react";
import intro from "../elementos/intro.mp4";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../App.css";
import animation from "../elementos/datosImagen.json";
import pay from "../elementos/pay.json";
import geolocalizacion from "../elementos/geolocalizacion.json";
import pedidos from "../elementos/pedidos.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import titulo1 from "../elementos/TITULO_CONTACT_CENTER.svg";
import icon1 from "../elementos/BESTCONTACTICON1.svg";
import icon2 from "../elementos/BESTCONTACTICON2.svg";
import icon3 from "../elementos/BESTCONTACTICON3.svg";
import icon4 from "../elementos/BESTCONTACTICON4.svg";
import icon5 from "../elementos/BESTCONTACTICON5.svg";
import icon6 from "../elementos/BESTCONTACTICON6.svg";
import icon7 from "../elementos/BESTCONTACTICON7.svg";
import agregado1 from "../elementos/AGREGADO1.svg";
import agregado2 from "../elementos/AGREGADO2.svg";
import agregado3 from "../elementos/AGREGADO3.svg";
import agregado4 from "../elementos/AGREGADO4.svg";
import agregado5 from "../elementos/AGREGADO5.svg";
import Contacto from "./Contacto.js";
import slider1 from "../elementos/VENTAJASBESTCONTACT1.svg";
import slider2 from "../elementos/VENTAJASBESTCONTACT2.svg";
import slider3 from "../elementos/VENTAJASBESTCONTACT3.svg";
import slider4 from "../elementos/VENTAJASBESTCONTACT4.svg";
import slider5 from "../elementos/VENTAJASBESTCONTACT5.svg";
import slider6 from "../elementos/VENTAJASBESTCONTACT6.svg";
import slider7 from "../elementos/VENTAJASBESTCONTACT7.svg";
import slider8 from "../elementos/VENTAJASBESTCONTACT8.svg";
import slider9 from "../elementos/VENTAJASBESTCONTACT9.svg";
import slider10 from "../elementos/VENTAJASBESTCONTACT10.svg";

import Grafica1 from "../elementos/bestcontactimage.js";
import robot from "../elementos/robot.svg";
import grafica2 from "../elementos/CONTACTCENTER_diagrama.svg";

import { FaArrowRight, FaArrowLeft, FaShoppingCart } from "react-icons/fa";
const images = [
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider8,
  slider9,
  slider10,
];

export default function BestContactCenter() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();
  let animationContainer3 = React.createRef();
  let animationContainer4 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation,
    });
    anim.setSpeed(2);
  }, []);
  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: pay,
    });
    anim.setSpeed(2);
  }, []);
  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer3.current,
      animationData: geolocalizacion,
    });
    anim.setSpeed(2);
  }, []);
  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer4.current,
      animationData: pedidos,
    });
    anim.setSpeed(2);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  return (
    <div>
      <Navegacion />
     
      <div className="containerCall">
        <div className="tituloQuienes2">
          <img src={titulo1} />
        </div>
        <div className="videoCallcenter">
          <div className="conjunto_circle">
            <div className="circle"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
          </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
        </div>
      </div>
      {/*<div ref={animationContainer} />*/}
      <div className="containerContact">
        <div className="defContact">
          <p>
            Es un sistema profesional que centraliza todos los canales de
            comunicación cliente-empresa, y empresa-cliente.
          </p>
        </div>
      </div>
      <div className="grafica1">
        <Grafica1 />
      </div>
      <div className="robot">
        <div className="imagenRobot">
          <img src={robot} />
        </div>
        <div className="textoRobot">
          <p>
            Nuestro asistente virtual es una herramienta de comunicación que{" "}
            <b>
              te permite mejorar el servicio al cliente y la experiencia de
              usuario, articulando la inteligencia artificial con la
              inteligencia humana,
            </b>{" "}
            para dar respuesta automatizada a requerimientos de cualquier
            naturaleza.
          </p>
        </div>
      </div>
      <div className="contenedorGrafica2">
        <p className="tituloGrafica2">SUITE ASISTENTE VIRTUAL</p>
        <div className="grafica2">
          <img src={grafica2} />
        </div>
      </div>
      <div className="funcionalidades">
        <p className="tituloFuncionalidad">FUNCIONALIDADES</p>
        <div className="imagenFuncionalidad">
          <Link to="funcionalidadesGenerales">
            <img src={icon1} />
          </Link>
          <Link to="funcionalidadesRetail">
            <img src={icon2} />
          </Link>
          <Link to="funcionalidadesFinancieros">
            <img src={icon3} />
          </Link>
          <Link to="funcionalidadesSeguros">
            <img src={icon4} />
          </Link>
          <Link to="funcionalidadesSalud">
            <img src={icon5} />
          </Link>
          <Link to="funcionalidadesTurismo">
            <img src={icon6} />
          </Link>
          <Link to="funcionalidadesSoporteTecnico">
            <img src={icon7} />
          </Link>
        </div>
      </div>

      <div className="Pagos">
        <p className="tituloPagos">PAGOS</p>
        <div className="containerP">
          <p className="contenido">
            Nuestro sistema de integra a pasarelas de pago, para mejorar la
            experiencia de usuario y aumentar la efectividad de cierre de
            ventas. El usuario mantiene la experiencia en el chatbot y recibe el
            comprobante de la transacción en este canal.
          </p>

          <div className="imagenPagos" ref={animationContainer2} />
        </div>
      </div>

      <div className="geolocalizacion">
        <p className="tituloPagos">GEOLOCALIZACIÓN DE PUNTOS DE ATENCIÓN</p>
        <div className="containerP">
          <div className="imagenPagos" ref={animationContainer3} />
          <p className="contenido">
            A través del asistente puede compartir con sus clientes información
            de la ubicación GPS del teléfono móvil.En pocos segundos el usuario
            conocerá en un mapa los puntos más cercanos y detalles de su
            ubicación.
          </p>
        </div>
      </div>

      <div className="Pagos">
        <p className="tituloPagos">RASTREO DE PEDIDOS</p>
        <div className="containerP">
          <p className="contenido">
            El asistente cuenta con el módulo de gestión de pedidos, el cual
            hace seguimiento al ciclo de vida del producto o servicio hasta su
            entrega final. Mantiene al usuario informado de cada cambio de
            estado.
          </p>
          <div className="imagenPagos2" ref={animationContainer4} />
        </div>
      </div>

      <div className="agregado">
        <p className="tituloGraficay">VALOR AGREGADO</p>
        <div className="imagenFuncionalidad2">
          <img src={agregado1} />
          <img src={agregado2} />
          <img src={agregado3} />
          <img src={agregado4} />
          <img src={agregado5} />
        </div>
      </div>

      <p className="tituloGrafica2">CON NUESTRO AGENTE VIRTUAL PUEDES:</p>
      <div className="App">
        <Slider {...settings}>
          {images.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
              <img src={img} alt={img} />
            </div>
          ))}
        </Slider>
      </div>
      <p className="adquiere">ADQUIERE BESTCONTACTCENTER AHORA MISMO</p>
      <div className="botonPago">

     
<form className="checkout">
  <div className="inlineForm">

  <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="4b3a43650f5eebfc4f4e306a400b069c"
            data-epayco-amount="300000"
            data-epayco-name="BestContactCenter"
            data-epayco-description="BestContactCenter"
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-button="https://multimedia.epayco.co/epayco-landing/btns/boton-epayco-fondo-oscuro1.png
            "
            
            data-epayco-external="false"
            data-epayco-response="http://www.bestvoiper.com.co/feedback"
            data-epayco-confirmation="http://www.bestvoiper.com.co/feedback"
            data-epayco-methodconfirmation="get">
        </script>
  <FaShoppingCart className="iconS"/>
  </div>
</form>
</div>
      <Contacto name="bestcontactcenter"/>
    </div>
  );
}
