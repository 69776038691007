import React, { useState } from "react";
import "../App.css";
import world from "../elementos/world.json";
import Slider from "react-slick";
import intro from "../elementos/intro.mp4";
import TITULODID from "../elementos/TITULO_DIDI.svg";
import slider1 from "../elementos/DID1.svg";
import slider2 from "../elementos/DID2.svg";
import slider3 from "../elementos/DID3.svg";
import slider4 from "../elementos/DID4.svg";
import DiagramaDid from "../elementos/diagramadid.js"
import animation2 from "../elementos/bestcallcenter2.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import Contacto from "./Contacto.js";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { FaArrowRight, FaArrowLeft,FaShoppingCart } from "react-icons/fa";
const images = [slider1, slider2, slider3, slider4];

export default function BestDid() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: world,
    });
    anim.setSpeed(1);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animation2,
    });
    anim2.setSpeed(1);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  const [state, setState] = useState("");
  const [cantidad, setCantidad] = useState();
  const handle = (e) => {
    let entrada = (e.target.value.trim()).replace(/[\/\\\<\>]+/g,'');
    let valor = Number(entrada);
    let total = valor * 60000;
    setState(total);
    setCantidad(valor);
  };
  localStorage.clear()
  localStorage.setItem("precio", state);
  localStorage.setItem("cantidad", cantidad);
  localStorage.setItem("producto", "BESTDID");
  localStorage.setItem("item", "canales");
  let precioFinal2 = localStorage.getItem("precio");

  return (
    <div className="bigContainer">
      <Navegacion />
   

      <div className="containerCall">
      <div className="tituloQuienesDid">
         <img src={TITULODID}/>
         
        </div>
      <div className="videoCallcenter">
        <div className="conjunto_circle">
         <div className="circle"></div>
         <div className="circle2"></div>
         <div className="circle3"></div>
         </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
          
        </div>
       
        
      </div>

      <div className="containerDid">
        
        
        <div className="containerD">
        <div className="imagenDid" ref={animationContainer} />
        <p className="contenidoDid">  Servicio de numeración telefónica para las principales ciudades del mundo. 
            Permite recibir llamadas de sus clientes sin importar el país donde se encuentren, generando presencia a nivel mundial
            
        </p>
        </div>
       
      
        
       </div>
 

      <p className="tituloCaracteristicas">CARACTERISTICAS</p>
      <div className="App">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
      </div>
      <div className="canales">
        <div className="containerCanales">
         <p>Con nuestra tecnología sus clientes podrán contactarse <b>desde varios 
           canales y de manera simultanea a un mismo número.</b>
         </p>
        </div>

      </div>
      <div className="diagramaDid">
        <DiagramaDid />
      </div>
      <p className="adquiere">ADQUIERE BESTDID AHORA MISMO</p>
      <p className="adquiere1">Costo por canal <b>$60.000 COP</b></p>
      <p className="adquiere2">¿Cuántos canales deseas adquirir?</p>
      <div className="valorbox">
      <input type="text" name="numeroLicencias" onChange={handle} />
      </div>
      <p className="adquiereTotal"><b>Total:</b>$ {state} COP</p>
  
     
        <div className="continuar">
         
            <Link className="nosubr" to="/pago">
              <p style={{color: "white", fontFamily:"Mitr", textAlign:"center", fontSize:"2em", background:"#3d5785"}}>CONTINUAR</p>
            </Link>
          
    
        
      
     
      </div>
     <Contacto name="bestdid"/>
    </div>
  );
}
