import React from "react";
import "../App.css";
import about from "../elementos/initial_team.json";
import develop from "../elementos/develop.json";
import corporative from "../elementos/corporative_ip.json";
import team from "../elementos/team.json";
import grow from "../elementos/grow.json";
import titulo from "../elementos/titulo_quienes_somos.svg";
import bestvoiper from "../elementos/con_bestvoiper.svg";
import fondo1 from "../elementos/fondo1.svg";
import fondo2 from "../elementos/fondo2.svg";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales.js"
export default function Introduccion() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();
  let animationContainer3 = React.createRef();
  let animationContainer4 = React.createRef();
  let animationContainer5 = React.createRef();
  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: about,
    });
    anim.setSpeed(2);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: develop,
    });
    anim2.setSpeed(0.5);

    const anim3 = lottie.loadAnimation({
      container: animationContainer3.current,
      animationData: corporative,
    });
    anim3.setSpeed(0.5);
    const anim4 = lottie.loadAnimation({
      container: animationContainer4.current,
      animationData: team,
    });
    anim4.setSpeed(2);
    const anim5 = lottie.loadAnimation({
      container: animationContainer5.current,
      animationData: grow,
    });
    anim5.setSpeed(1);
  }, []);
  return (
    <div className="bigContainer">
      <Navegacion />
      <RedesSociales/>
      <div className="quienesSomos">
        
        <div className="cajaImagenes">
        <div className="imagenQuienes" ref={animationContainer} />
        <img src={fondo1}/>
        </div>
        <div className="tituloQuienes">
          <img src={titulo}/>
          
        </div>
      </div>

      <div className="desarrollo">
        <div className="imagenDesarrollo" ref={animationContainer2} />
        <div className="tituloDesarrollo">
          <p>
            Una compañía latinoamericana de desarrollo de software y
            comunicaciones.
          </p>
        </div>
      </div>

      <div className="gubernamental">
        <div className="tituloGubernamental">
          <p>
            Contamos con mucha experiencia en la implementación de soluciones de
            telefonía IP en ambientes corporativos, gubernamentales u oficiales.
          </p>
        </div>
        <div className="imagenGubernamental" ref={animationContainer3} />
      </div>

      <div className="team">
      <div className="imagenTeam" ref={animationContainer4} />
      <div className="tituloTeam">
        <p>
          Creamos soluciones en canales de atención que facilitan los medios de
          comunicación para fortalecer la relación entre tu empresa y tus
          clientes y asi contribuir al crecimiento de cualquier empresa.
        </p>
        </div>
        </div> 

      <div className="grow">
      <div className="tituloGrow">
      <img src={bestvoiper}/>
        </div>
        <div className="cajaImagenes2">
        <div className="imagenGrow" ref={animationContainer5} />
      
        </div>
      </div>
    </div>
  );
}
