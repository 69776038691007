import React, { useState } from "react";
import robot from "../elementos/robot.svg";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import { useNavigate, Link } from "react-router-dom";
import {useAuth} from '../context/authContext'
export default function Registro() {
  const {signUp} = useAuth()
  const navigate = useNavigate()
  const [user, setUser] = useState({
    email: "",
    pass: "",
    pass2: "",
  });
  const [error, setError] = useState()
  const handle = (e) =>{
    let name = (e.target.name.trim()).replace(/[\/\\\<\>]+/g,'');
    let value = (e.target.value.trim()).replace(/[\/\\\<\>]+/g,'');
    setUser({...user,[name]: value})
    
  }
  const send = async(e) =>{
    e.preventDefault()
    if(!user.email) return setError("Por favor ingresa tu correo")
    if(!user.pass) return setError("Por favor ingresa tu contraseña")
    if(!user.pass2) return setError("Por favor ingresa tu contraseña de nuevo")
    if(user.pass.length < 8) return setError("La contraseña debe tener 8 caracteres entre números, letras y simbolos")
    if(user.pass != user.pass2) return setError("Las contraseñas no coinciden")
   try {
    await signUp(user.email, user.pass)
    navigate("/login")
   } catch (error) {
     setError(error.message)
   }

  }

  return (
    <div>
        <RedesSociales/>

      <div className="formulario">
        <Navegacion />
        <img src={robot} />
      
        <form onSubmit={send}>
        <p className="tituloForm">REGISTRATE</p>
          <input onChange={handle} type="email" placeholder="correo" name="email" />
          <input  onChange={handle} type="password" placeholder="Contraseña" name="pass" />
          <input  onChange={handle} type="password" placeholder="Contraseña" name="pass2" />
          {error ?(<p className="error">{error}</p>): null}
          <input type="submit" value="REGISTRARSE" name="enviar" />
          <Link className="noUnderline" to="/login"> 
          <p className="registro">Iniciar Sesión</p>
          </Link>
        </form>
      </div>
    </div>
  );
}
