import React, { useEffect, useRef } from "react";
import imagenProductos from "../elementos/productos.svg";
import uno from "../elementos/Pbestcall.svg";
import dos from "../elementos/Pbestcontact.svg";
import tres from "../elementos/Pbestdid.svg";
import cuatro from "../elementos/Pbestip.svg";
import cinco from "../elementos/Pbestpbx.svg";
import seis from "../elementos/Pbmasivo.svg";
import title from "../elementos/nuestrosProductos.svg";
import "../App.css";
import Navegacion from "./Navegacion";
import RedesSociales from "./RedesSociales.js"
import Doodle from "../elementos/productos.svg";
import Rueda from "../elementos/Rueda.js";
import {Link} from 'react-router-dom'
export default function Productos() {
  return (
    <div>
      <Navegacion />
      <RedesSociales/>
      <div className="imagenProductos">
        <div className="adornoProductos">
          <img src={Doodle} />
        </div>
        <div className="rueda">
          <Rueda />
        </div>
        <div className="PList">
          <div className="listaProductos">
          <img id="nuestroProductos" src={title}/>
          <Link to="bestcallcenter">
          <img id="uno" src={uno}/>
          </Link>
          <Link to="bestcontactcenter">
          <img id="dos" src={dos}/>
          </Link>
          <Link to="bestdid">
          <img id="tres" src={tres}/>
          </Link>
          <Link to="bestiptrunk">
          <img  id="cuatro" src={cuatro}/>
          </Link>
          <Link to="bestpbx">
          <img id="cinco" src={cinco}/>
          </Link>
          <Link to="masivos">
          <img id="seis" src={seis}/>
          </Link>
          

          </div>
         
        </div>
      </div>
    </div>
  );
}
