import React, { useState } from "react";
import robot from "../elementos/robot.svg";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/authContext";
export default function Login() {
  const { signIn, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    pass: "",
  });

  const [error, setError] = useState();
 

  
  const handle = (e) =>{
    let name = (e.target.name.trim()).replace(/[\/\\\<\>]+/g,'');
    let value = (e.target.value.trim()).replace(/[\/\\\<\>]+/g,'');
    setUser({...user,[name]: value})
    
  }

  const handleGoogle = async () => {
    await loginWithGoogle();
    navigate("/tutoriales");
  };
  const send = async (e) => {
    e.preventDefault();
    if(!user.email) return setError("Por favor ingresa tu correo")
    if(!user.pass) return setError("Por favor ingresa tu contraseña")
    try {
      await signIn(user.email, user.pass);
      navigate("/tutoriales");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div>
      <RedesSociales/>
      
      <div className="formulario">
        <Navegacion />
        
        <img src={robot} />
        

        <form onSubmit={send}>
          <p className="tituloForm">LOGIN</p>
          <input
            onChange={handle}
            type="email"
            placeholder="Correo"
            name="email"
          />
          <input
            onChange={handle}
            type="password"
            placeholder="Contraseña"
            name="pass"
          />
           {error ?(<p className="error">{error}</p>): null}
          <input type="submit" value="ENTRAR" name="enviar" />
          
         
          <button className="google" onClick={handleGoogle}>
            ENTRAR CON GOOGLE
          </button>
          <Link className="noUnderline" to="/registro">
            <p className="registro">Registrate</p>
          </Link>
          <Link className="noUnderline" to="/reset">
            <p className="olvido">¿Olvidaste tu contraseña?</p>
          </Link>
        </form>
      </div>
    </div>
  );
}
