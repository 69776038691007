import React, { useState } from "react";
import "../App.css";
import about from "../elementos/about.json";
import Slider from "react-slick";
import intro from "../elementos/intro.mp4";
import slider1 from "../elementos/PBX1.svg";
import slider2 from "../elementos/PBX2.svg";
import slider3 from "../elementos/PBX3.svg";
import slider4 from "../elementos/PBX4.svg";
import slider5 from "../elementos/PBX5.svg";
import slider6 from "../elementos/PBX6.svg";
import slider7 from "../elementos/PBX7.svg";
import slider8 from "../elementos/PBX8.svg";
import TITULO_PBX from "../elementos/TITULO_PBX.svg";
import aplicacion1 from "../elementos/APLICACIONES1.svg";
import aplicacion2 from "../elementos/APLICACIONES2.svg";
import aplicacion3 from "../elementos/APLICACIONES3.svg";
import aplicacion4 from "../elementos/APLICACIONES4.svg";
import aplicacion5 from "../elementos/APLICACIONES5.svg";
import aplicacion6 from "../elementos/APLICACIONES6.svg";
import aplicacion7 from "../elementos/APLICACIONES7.svg";
import aplicacion8 from "../elementos/APLICACIONES8.svg";
import aplicacion9 from "../elementos/APLICACIONES9.svg";
import aplicacion10 from "../elementos/APLICACIONES10.svg";
import animation1 from "../elementos/pbx.json";
import animation2 from "../elementos/bestcallcenter2.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import Contacto from "./Contacto.js";
import { FaArrowRight, FaArrowLeft, FaShoppingCart } from "react-icons/fa";
const images = [slider1, slider2, slider3, slider4, slider5, slider6,
slider7, slider8];

export default function BestPbx() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation1,
    });
    anim.setSpeed(1);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animation2,
    });
    anim2.setSpeed(1);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <div className="bigContainer">
      <Navegacion />
   
      <div className="containerCall">
      <div className="videoCallcenter">
        <div className="conjunto_circle">
         <div className="circle"></div>
         <div className="circle2"></div>
         <div className="circle3"></div>
         </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
          
        </div>
        <div className="tituloPBX">
         <img src={TITULO_PBX}/>
         
        </div>
        
      </div>

      <div className="containerPBX">
        <div className="containerP">
          <p className="contenidoPBX">
          
          Sistema para pequeñas empresas que deben
              grabar llamadas y autogestionar sus extensiones.
          </p>
          <div className="imagenPBX" ref={animationContainer} />
        </div>
      </div>
    

      <p className="tituloCaracteristicas">CARACTERISTICAS</p>
      <div className="App">
      <Slider {...settings}>
        {images.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
          </div>
        ))}
      </Slider>
      </div>
      <p className="adquiere">ADQUIERE BESTPBX AHORA MISMO</p>
      <div className="botonPago">

     
<form className="checkout">
  <div className="inlineForm">

  <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="4b3a43650f5eebfc4f4e306a400b069c"
            data-epayco-amount="300000"
            data-epayco-name="BestPbx"
            data-epayco-description="BestPbx"
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-button="https://multimedia.epayco.co/epayco-landing/btns/boton-epayco-fondo-oscuro1.png
            "
            
            data-epayco-external="false"
            data-epayco-response="http://www.bestvoiper.com.co/feedback"
            data-epayco-confirmation="http://www.bestvoiper.com.co/feedback"
            data-epayco-methodconfirmation="get">
        </script>
  <FaShoppingCart className="iconS"/>
  </div>
</form>
</div>
  <Contacto name="bestpbx"/>
    </div>
  );
}
