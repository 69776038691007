import React, { useState } from "react";
import robot from "../elementos/robot.svg";
import Navegacion from "./Navegacion.js";
import RedesSociales from "./RedesSociales";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/authContext";
export default function Login() {
  const { signIn, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    pass: "",
  });

  const [error, setError] = useState();
  const handle = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };
  const handleGoogle = async () => {
    await loginWithGoogle();
    navigate("/tutoriales");
  };
  const send = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(user.email, user.pass);
      navigate("/tutoriales");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div>
       <RedesSociales/>
      <div className="formulario">
        <Navegacion />
       
        <img src={robot} />
       <p className="adquiere2">
           ¡Gracias por su compra!
       </p>

      </div>
    </div>
  );
}
