import React, { useState, useEffect } from "react";
import "../App.css";
import about from "../elementos/about.json";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Slider from "react-slick";
import intro from "../elementos/bestcallcenterpro_video.mp4";
import slider1 from "../elementos/CARACTERISTICASBESTCALLCENTERPRO1.svg";
import slider2 from "../elementos/CARACTERISTICASBESTCALLCENTERPRO2.svg";
import slider3 from "../elementos/CARACTERISTICASBESTCALLCENTERPRO3.svg";
import slider4 from "../elementos/CARACTERISTICASBESTCALLCENTERPRO4.svg";
import slider5 from "../elementos/CARACTERISTICASBESTCALLCENTERPRO5.svg";
import aplicacion1 from "../elementos/APLICACIONES1.svg";
import aplicacion2 from "../elementos/APLICACIONES2.svg";
import aplicacion3 from "../elementos/APLICACIONES3.svg";
import aplicacion4 from "../elementos/APLICACIONES4.svg";
import aplicacion5 from "../elementos/APLICACIONES5.svg";
import aplicacion6 from "../elementos/APLICACIONES6.svg";
import aplicacion7 from "../elementos/APLICACIONES7.svg";
import aplicacion8 from "../elementos/APLICACIONES8.svg";
import aplicacion9 from "../elementos/APLICACIONES9.svg";
import aplicacion10 from "../elementos/APLICACIONES10.svg";
import titulo from "../elementos/TITULO_CALL_CENTER.svg";
import fondo1 from "../elementos/fondo1.svg";
import animation1 from "../elementos/bestcallcenter1.json";
import animation2 from "../elementos/bestcallcenter2.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import Toogle from "./DropDown.js";
import RedesSociales from "./RedesSociales.js";
import Contacto from "./Contacto.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaArrowRight,
  FaArrowLeft,
  FaShoppingCart,
  FaPhone,
} from "react-icons/fa";
const images = [slider1, slider2, slider3, slider4, slider5];

export default function BestCallCenter() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation1,
    });
    anim.setSpeed(1);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animation2,
    });
    anim2.setSpeed(1);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);
  

  const settings = {
    lazyLoad: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPading: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };
  const [state, setState] = useState("");
  const [cantidad, setCantidad] = useState();
  const handle = (e) => {
    let entrada = (e.target.value.trim()).replace(/[\/\\\<\>]+/g,'');
    let valor = Number(entrada);
    let total = valor * 60000;
    setState(total);
    setCantidad(valor);
  };
  localStorage.clear()
  localStorage.setItem("precio", state);
  localStorage.setItem("cantidad", cantidad);
  localStorage.setItem("producto", "BESTCALLCENTERPRO");
  localStorage.setItem("item", "licencias");
  let precioFinal2 = localStorage.getItem("precio");
  return (
    <div className="bigContainer">
      <Navegacion />

      <div className="containerCall">
        <div className="videoCallcenter">
          <div className="conjunto_circle">
            <div className="circle"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
          </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
        </div>
        <div className="tituloQuienes2">
          <img src={titulo} />
        </div>
      </div>

      <div className="definicionBestCall">
        <div className="cajaHorizontal1">
          <div className="imagenCall" ref={animationContainer} />
          <ul>
            <p className="encabezado">
              Herramienta con funciones avanzadas que permiten a teleoperadores,
              asesores o agentes de atención al cliente:
            </p>
            <li className="li1">
              <p>
                Recibir y realizar llamadas a clientes actuales y potenciales
                con el objetivo de vender un producto
              </p>
            </li>
            <li>
              <p>Realizar una encuesta</p>
            </li>
            <li>
              <p>Ofrecer un servicio</p>
            </li>
            <li>
              <p>Recibir llamadas para responder inquietudes tomar pedidos</p>
            </li>
            <li>
              <p>Registrar reclamos, sondeos, entre otros.</p>
            </li>
          </ul>
        </div>

        <div className="cajaHorizontal2">
          <p>
            Nuestro software de call center todo-en-uno te permite establecer
            una comunicación con tus clientes, proveedores o socios comerciales.
          </p>
          <div className="imagenCall" ref={animationContainer2} />
        </div>
      </div>

      <p className="tituloCaracteristicas">CARACTERISTICAS</p>
      <div className="App">
        <Slider {...settings}>
          {images.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
              <img src={img} alt={img} />
            </div>
          ))}
        </Slider>
      </div>

      <p className="tituloGrafica2">HERRAMIENTAS</p>
      <div className="max">
        <Toogle />
      </div>
      <div className="agregado">
        <p className="tituloGrafica2">APLICACIONES</p>
        <div className="imagenFuncionalidad3">
          <img src={aplicacion1} />
          <img src={aplicacion2} />
          <img src={aplicacion3} />
          <img src={aplicacion4} />
          <img src={aplicacion5} />
          <img src={aplicacion6} />
          <img src={aplicacion7} />
          <img src={aplicacion8} />
          <img src={aplicacion9} />
          <img src={aplicacion10} />
        </div>
      </div>
      <p className="adquiere">ADQUIERE BESTCALLCENTER PRO AHORA MISMO</p>
      <p className="adquiere1">Costo por licencia <b>$60.000 COP</b></p>
      <p className="adquiere2">¿Cuántas licencias deseas adquirir?</p>
      <div className="valorbox">
      <input type="text" name="numeroLicencias" onChange={handle} />
      </div>
      <p className="adquiereTotal"><b>Total:</b>$ {state} COP</p>
  
     
        <div className="continuar">
         
            <Link className="nosubr" to="/pago">
              <p style={{color: "white", fontFamily:"Mitr", textAlign:"center", fontSize:"2em", background:"#3d5785"}}>CONTINUAR</p>
            </Link>
          
    
        
      
     
      </div>
      <Contacto name="bestcallcenter"/>
    </div>
  );
}
