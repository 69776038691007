import React from "react";
import "../App.css";

import RedesSociales from "./RedesSociales.js"
import Navegacion from './Navegacion.js'
export default function Politicas() {
  return (
    <div className="politicasContainer">
        <Navegacion/>
        <RedesSociales/>
      <h1 className="tituloPolitica">POLITICA DE PRIVACIDAD</h1>

      <h2 className="subtitle">1. ALCANCE</h2>

      <p className="contenidoPoliticas">
        En cumplimiento a lo dispuesto en la Ley estatutaria 1581 de 2012 y a su
        Decreto Reglamentario 1377 de 2013, así como sus normas modificatorias
        y/o complementarias, el Grupo Colombia Excelente SAS, en adelante EL
        GRUPO, informa la política aplicable, para el tratamiento protección de
        datos personales. Esta Política de Protección de Bases Datos Personales
        (en adelante BDP), se aplicará a todas las Bases de Datos y/o Archivos
        que contengan Datos Personales de personas naturales que sean objeto de
        Tratamiento por parte de Bestvoiper S A S.
      </p>
      <br />

      <h3 className="subtituloContenidos">
        2. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES
      </h3>
      <p className="interlineadoBase">
        <b className="numerales">1.</b>
        <b> Razón Social:</b> Bestvoiper S A S (en adelante Bestvoiper)
      </p>
      <br />
      <p className="interlineado">
        <b className="numerales">2.</b>
        <b> Dirección:</b> Calle 26 102 20 OF 303, Bogota, Bogota{" "}
      </p>
      <br />
      <p className="interlineado">
        <b className="numerales">3.</b>
        <b>Número de Identificación Tributaria:</b> NIT N° 900.591.316-1
      </p>
      <br />
      <p className="interlineado">
        <b className="numerales">4.</b>
        <b> Correo:electrónico:</b> info@bestvoiper.com{" "}
      </p>
      <br />
      <p className="interlineado">
        <b className="numerales">5.</b>
        <b> Sitio Web:</b> https://bestvoiper.com.co/{" "}
      </p>

      <h3 className="subtituloContenidos">3. ORGANIZACIÓN INTERNA.</h3>
      <p>El Tratamiento de BDP contará con:</p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> Un Responsable que será Bestvoiper,
        persona jurídica de nacionalidad colombiana, identificada con los datos
        establecidos en la identificación del presente documento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2.</b>Uno o más empleados, en calidad de
        usuarios de Bestvoiper quienes serán los únicos autorizados para
        actualizar y modificar las BDP, además de responder las consultas y/o
        solicitudes de los titulares de la información.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b>Un Oficial de Bases de Datos que será
        designado por EL GRUPO.
      </p>

      <h3 className="subtituloContenidos">4. DEFINICIONES</h3>
      <p>
        Salvo lo establecido en el presente documento, se adoptarán para la
        aplicación e interpretación de las BDP, los términos y definiciones
        establecidos en materia de protección de BDP en la Ley 1581 de 2012, así
        como en el Decreto 1074 de 2015, además de sus normas complementarias
        y/o modificatorias.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> <b>Autorización:</b> Consentimiento
        previo, expreso e informado del Titular para llevar a cabo el
        Tratamiento de Datos Personales
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2. </b>
        <b>Aviso de Privacidad:</b> Comunicación verbal o escrita generada por
        el Responsable, dirigida al Titular para el Tratamiento de sus Datos
        Personales, mediante la cual se le informa acerca de la existencia de
        las Políticas de Tratamiento de información que le serán aplicables, la
        forma de acceder a las mismas y las finalidades del Tratamiento que se
        pretende dar a los datos personales
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b> <b>Base de Datos:</b> Conjunto
        organizado de Datos Personales que sean objeto de Tratamiento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">4.</b>
        <b> Dato Personal:</b> Cualquier información vinculada o que pueda
        asociarse a una o varias personas naturales determinadas o determinables
      </p>
      <p className="interlineadoBase">
        <b className="numerales">5.</b>
        <b> Dato Público:</b> Es el dato que no sea semiprivado, privado o
        sensible. Son considerados datos públicos, entre otros, los datos
        relativos al estado civil de las personas, a su profesión u oficio y a
        su calidad de comerciante o de servidor público. Por su naturaleza, los
        datos públicos pueden estar contenidos, entre otros, en registros
        públicos, documentos públicos, gacetas y boletines oficiales y
        sentencias judiciales debidamente ejecutoriadas que no estén sometidas a
        reserva.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">6.</b>
        <b> Encargado del Tratamiento:</b> Persona natural o jurídica, pública o
        privada, que por sí misma o en asocio con otros, realiza el Tratamiento
        de Datos Personales por cuenta del Responsable del Tratamiento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">7.</b>
        <b> Responsable del Tratamiento:</b> Persona natural o jurídica, pública
        o privada, que por sí misma o en asocio con otros, decida sobre la Base
        de Datos y/o el Tratamiento de los datos.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">8.</b>
        <b> Términos y Condiciones:</b> Marco general en el cual se establecen
        las condiciones de acceso y uso del Sitio Web de Bestvoiper.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">9.</b>
        <b>Titular:</b> Persona natural cuyos Datos Personales sean objeto de
        Tratamiento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">10.</b>
        <b>Tratamiento:</b> Cualquier operación o conjunto de operaciones sobre
        Datos Personales, tales como la recolección, almacenamiento, uso,
        circulación o supresión.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">11.</b>
        <b>Transferencia:</b> La transferencia de datos tiene lugar cuando el
        Responsable y/o Encargado del Tratamiento de datos personales, ubicado
        en Colombia, envía la información o los datos personales a un receptor,
        que a su vez es Responsable del Tratamiento y se encuentra dentro o
        fuera del país
      </p>
      <p className="interlineadoBase">
        <b className="numerales">12.</b>
        <b>Ley de Protección de Datos:</b> Es la Ley 1581 de 2012 y sus Decretos
        reglamentarios o las normas que los modifiquen, complementen o
        sustituyan.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">13.</b>
        <b>Habeas Data:</b> Derecho de cualquier persona a conocer, actualizar y
        rectificar las informaciones que se hayan recogido sobre ellas en el
        banco de datos y en archivos de entidades públicas y privadas.
      </p>
      <h3 className="subtituloContenidos">5. PRINCIPIOS</h3>
      <p>
        La protección de las BDP y sus contenidos, se regirán por los siguientes
        principios:
      </p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> <b>Legalidad:</b> el Tratamiento de las
        BDP, debe desarrollarse dentro de los límites establecidos por la
        normatividad vigente.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2.</b> <b>Finalidad: </b>el Tratamiento de las
        BDP, debe obedecer a una finalidad legítima de acuerdo con la
        Constitución y la Ley.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b> <b>Autodeterminación informática:</b>{" "}
        posibilidad de la cual dispone una persona para permitir que sus datos
        se almacenen, circulen y sean usados de conformidad con las regulaciones
        legales.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">4.</b> <b>Libertad:</b> el Tratamiento de las
        BDP sólo puede ejercerse con el consentimiento, previo, expreso e
        informado del Titular. Los datos personales no podrán ser obtenidos o
        divulgados sin previa autorización, o en ausencia de mandato legal o
        judicial que releve el consentimiento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">5.</b> <b>Seguridad:</b> se realizará el
        tratamiento con medidas técnica, humanas y administrativas destinadas a
        disminuir el riesgo de adulteración, pérdida, consulta o acceso no
        autorizado fraudulento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">6.</b> <b>Transparencia:</b> en el tratamiento
        debe garantizarse el derecho del titular a obtener del responsable, en
        cualquier momento y siempre que sea posible, información acerca de la
        existencia de datos que le conciernen.
      </p>
      <h3 className="subtituloContenidos">6. TRATAMIENTO</h3>
      <p>
        Bestvoiper, actuando en calidad de Responsable del Tratamiento de las
        BDP, de acuerdo con la finalidad y necesidad de cada una de ellas,
        contará con las siguientes políticas generales de tratamiento:
      </p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> En la recolección y tratamiento de datos
        se respetará el principio de libertad y demás garantías consagradas en
        la Constitución y en la Ley.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2.</b> Se solicitará previa autorización
        expresa y voluntaria a toda persona para disponer de su información
        personal, siempre que la misma no sea pública.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b> La el tratamiento de datos personales se
        realizará a través de recepción de correos y/o formularios, así como la
        suscripción de contratos laborales, de prestación de servicios y con
        proveedores.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">4.</b> Todo uso y/o tratamiento de las BDP
        deberá ser previamente autorizado por el Gerente General.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">5.</b> Sólo aquellos empleados con funciones
        específicas podrán actualizar, corregir y suprimir la información de las
        BDP por solicitud del titular y/o inexactitud de los datos. Así mismo
        son los únicos autorizados para responder las quejas, denuncias y/o
        reclamos de los titulares de la información, quienes informarán de tales
        eventos al Oficial de bases de datos.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">6.</b> Se establecerán como canales mínimos de
        información comunicación mínima a favor de los titulares de los datos
        personales: (i) dirección física y (ii) correo electrónico, indicados en
        el alcance del presente documento, para que aquellos puedan consultar,
        rectificar y/o revocar la autorización respecto del uso de sus datos
        personales.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">7.</b> La información sujeta a Tratamiento se
        deberá manejar con las medidas técnicas, humanas y administrativas que
        sean necesarias para otorgar seguridad a los registros evitando su
        adulteración, pérdida, consulta, uso o acceso no autorizado o
        fraudulento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">8.</b> Todas las personas que intervengan en el
        Tratamiento de datos personales, están obligadas a garantizar la reserva
        de la información, inclusive después de finalizada su relación.
      </p>
      <h3 className="subtituloContenidos">7. FINALIDAD</h3>
      <p>
        Las BDP objeto de Tratamiento por parte del Bestvoiper, podrán contar
        con las siguientes finalidades:
      </p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> Participación en distintos tipos de
        encuestas mediante registros o formularios online.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2.</b> Llenado de solicitudes de suscripción de
        formatos para capacitación, servicios online o bien para facilitar
        información relacionada con los servicios que ofrece el Bestvoiper.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b> Hacer más sencillo el uso del Sitio Web.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">4.</b> Adaptar el contenido más relevante de
        acuerdo con las preferencias de los usuarios del Sitio Web.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">5.</b> Informar las noticias importantes
        concernientes al Bestvoiper.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">6.</b> Informar sobre los productos o
        servicios, actualizaciones de productos, eventos y eventos especiales.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">7.</b> Promocionar productos y servicios en
        nuestra del Sitio Web.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">8.</b> Velar por la seguridad e integridad de
        nuestra del Sitio Web.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">9.</b> Como parte de un proceso continuo de
        venta.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">10.</b>Facilitar el soporte técnico o
        consultoría respecto de algún producto.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">11.</b>Formar parte del proceso de
        mantenimiento y modernización de nuestros productos.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">12.</b>Formar parte de medios automatizados
        como protocolos de comunicación.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">13.</b>En general para todo tipo de actividad
        relacionada con el desarrollo del objeto social de la organización.
      </p>
      <h3 className="subtituloContenidos">
        8. DERECHOS DE LOS TITULARES DE LOS DATOS PERSONALES
      </h3>
      <p>
        Las personas naturales cuyos Datos Personales sean objeto de Tratamiento
        por parte del Bestvoiper, tienen los siguientes derechos, los cuales
        pueden ejercer en cualquier momento:
      </p>
      <p className="interlineadoBase">
        <b className="numerales">1.</b> Conocer sus Datos Personales sobre los
        cuales el Bestvoiper está realizando el Tratamiento. De igual manera, el
        Titular puede solicitar en cualquier momento que sus datos sean
        actualizados o rectificados, o suprimidos por ejemplo, si encuentra que
        sus datos son parciales, inexactos, incompletos, fraccionados, induzcan
        a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no
        haya sido autorizado.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">2.</b> Ser informado por el Bestvoiper, previa
        solicitud, respecto del uso que éste le ha dado a sus Datos Personales.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">3.</b> Presentar ante la Superintendencia de
        Industria y Comercio quejas por infracciones a lo dispuesto en la Ley de
        Protección de Datos Personales.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">4.</b> Solicitar al Bestvoiper la supresión de
        sus Datos Personales y/o revocar la autorización otorgada para el
        Tratamiento de los mismos, mediante la presentación de un reclamo, de
        acuerdo con los procedimientos establecidos en esta Política. No
        obstante, la solicitud de supresión de la información y la revocatoria
        de la autorización no procederán cuando el Titular de la información
        tenga un deber legal o contractual de permanecer en la Base de Datos y/o
        Archivos, ni mientras se encuentre vigente la relación entre el Titular
        y el Grupo, en virtud de la cual fueron recolectados sus datos.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">5.</b> Conocer de forma gratuita a sus Datos
        Personales objeto de Tratamiento.
      </p>
      <p className="interlineadoBase">
        <b className="numerales">6.</b> Garantizar al titular en todo momento,
        siempre que sea posible, el ejercicio del derecho de Habeas Data
      </p>
      <h3 className="subtituloContenidos">9. DECLARACIÓN DE PRIVACIDAD.</h3>
      <p>
        La privacidad de la información de los titulares es muy importante para
        Bestvoiper. Es por esa razón que se toman las precauciones y recaudos
        para resguardar su información. Bestvoiper recopila la información de
        los titulares de los datos personales con fines estadísticos internos,
        compartiéndola con sus empresas relacionadas, así como para evaluar sus
        preferencias y ofrecerles un mejor servicio. La información recibida,
        tendrá como finalidad realizar tratamiento administración contable,
        oferta de productos y servicios por parte de Bestvoiper y/o de terceros,
        así como de las empresas relacionadas y/o vinculadas, a quien se les
        podrá enviar las bases de datos personales. Los titulares de los datos
        personales podrán ejercitar los derechos de conocer, acceder, retirar y
        actualizar su Información Personal, así como a oponerse al tratamiento
        de la misma y a ser informado de las cesiones llevadas a cabo, todo ello
        de conformidad a lo dispuesto en la normativa aplicable. Los titulares
        de los datos personales garantizan y responden, en cualquier caso, de la
        veracidad, exactitud, vigencia y autenticidad de la Información Personal
        facilitada y se comprometen a mantenerla debidamente actualizada. El
        titular de los datos personales podrá revisar, actualizar, y solicitar
        desactivar enviando un correo a la dirección indicada en el alcance del
        presente documento.
      </p>

      <p>
        Bestvoiper podrá mantener información personal que previamente haya sido
        objeto de modificación o eliminación de los titulares de los datos
        personales, con la finalidad de resolver disputas o reclamaciones,
        detectar problemas o incidencias y solucionarlos. Bestvoiper podrá
        enviar información o mensajes por e-mail sobre nuevos servicios, mostrar
        publicidad o promociones, banners, de interés para los titulares de los
        datos personales, noticias además de la información expresamente
        autorizada en la sección de preferencias. Si el titular de los datos
        personales lo prefiere, puede solicitar que lo excluyan de las listas
        para el envío de información promocional o publicitaria enviando un
        correo a la dirección indicada en el alcance del presente documento. El
        titular puede ejercer sus derechos enviando un correo electrónico y/o
        una nota física a las direcciones establecidas en la identificación del
        presente documento.
      </p>
      <h3 className="subtituloContenidos">10. COOKIES.</h3>
      <p>
        El titular de los datos personales conoce y acepta que Bestvoiper podrá
        utilizar un sistema de seguimiento mediante la utilización de cookies
        (las «Cookies»). Las Cookies son pequeños archivos que se instalan en el
        disco rígido, con una duración limitada en el tiempo que ayudan a
        personalizar los servicios. Las Cookies se utilizan con el fin de
        conocer los intereses, el comportamiento y la demografía de quienes
        visitan o son titulares de los datos personales de su sitio web y de esa
        forma, comprender mejor sus necesidades e intereses y darles un mejor
        servicio o proveerles información relacionada. También usaremos la
        información obtenida por intermedio de las Cookies para analizar las
        páginas navegadas por el visitante o titulares de los datos personales,
        las búsquedas realizadas, mejorar nuestras iniciativas comerciales y
        promocionales, mostrar publicidad o promociones, banners de interés,
        noticias sobre Bestvoiper, así como personalizar dichos contenidos,
        presentación y servicios; también podremos utilizar Cookies para
        promover y hacer cumplir las reglas y seguridad de Bestvoiper.
        Utilizamos adicionalmente las Cookies para que el titular de los datos
        personales no tenga que introducir su clave tan frecuentemente durante
        una sesión de navegación, también para contabilizar y corroborar las
        inscripciones, la actividad del titular de los datos personales y otros
        conceptos para acuerdos comerciales, siempre teniendo como objetivo de
        la instalación de las Cookies, el beneficio del titular de los datos
        personales que la recibe.
      </p>
      <p>
        Se establece que la instalación, permanencia y existencia de las Cookies
        en el computador del titular de los datos personales depende de su
        exclusiva voluntad y puede ser eliminada de su computador cuando el
        titular de los datos personales así lo desee. Se aclara expresamente que
        estas políticas cubren la utilización de Cookies y no la utilización de
        Cookies por parte de anunciantes. Nosotros no controlamos el uso de
        Cookies por terceros. Bestvoiper no se hace responsable por
        interceptaciones ilegales o violación de sus sistemas o bases de datos
        por parte de personas no autorizadas. Bestvoiper tampoco se hace
        responsable por la indebida utilización de la información obtenida por
        esos medios.
      </p>

      <h3 className="subtituloContenidos">
        11. TRANSFERENCIA DE LA INFORMACIÓN
      </h3>
      <p>
        Transferimos su información para determinados propósitos y a terceros,
        de la siguiente manera:
      </p>
      <p>
        <b>Empresas o instituciones vinculadas a Bestvoiper:</b> Compartimos su
        información con las empresas o instituciones vinculadas a Bestvoiper,
        según sea necesario para: procesar y almacenar datos; proveer acceso a
        nuestros servicios; proveer servicio al cliente; tomar decisiones sobre
        mejoras en el servicio y desarrollo de contenido; y de acuerdo con lo
        descrito en esta Declaración de privacidad.
      </p>
      <p>
        <b>Proveedores de servicios:</b> Usamos otras empresas, agentes o
        contratistas (“Proveedores de servicios”) para que presten servicios en
        nuestro nombre o para que nos ayuden en la prestación del servicio. En
        el transcurso de la prestación de dichos servicios, estas empresas
        pueden tener acceso a su información personal o de otro tipo, que en
        todo caso se rigen por acuerdos de confidencialidad.{" "}
      </p>
      <p>
        <b>Programas académicos formales o no formales:</b> Podemos ofrecer
        promociones o programas conjuntos que requieran el intercambio de
        información con terceros como condición para participar. Al realizar
        este tipo de promociones, podremos compartir su nombre y otra
        información relacionada con la realización del incentivo. Tenga en
        cuenta que los terceros son responsables de sus propias prácticas de
        privacidad.{" "}
      </p>
      <p>
        <b>Transferencias de negocios:</b> En relación con una reorganización,
        constitución de sociedades, reestructuración, fusión o venta, o
        cualquier otra transferencia de bienes, nosotros transferiremos
        información, incluida la personal, a condición de que el destinatario se
        comprometa a respetar su información personal de conformidad con nuestra
        Declaración de privacidad.
      </p>
      <h3 className="subtituloContenidos">12. MODIFICACIONES.</h3>
      <p>
        Bestvoiper podrá modificar la Declaración de privacidad en cualquier
        momento difundiendola en el sitio web establecido en la identificación
        del presente documento, sin notificación a los titulares de los datos
        personales, quienes deberán en todo momento estar pendientes de toda
        posible modificación. Si el titular de los datos personales no está de
        acuerdo el aviso de Privacidad, puede enviar en cualquier momento una
        nota física a la siguiente dirección física y/o un correo electrónico a
        las direcciones indicadas en el alcance del presente documento,
        revocando expresamente su autorización respecto del tratamiento de sus
        datos personales, caso en el cual deberá abstenerse de utilizar de forma
        definitiva y automática los productos y servicios de Bestvoiper. Oficial
        de Bases de datos.
      </p>
      <p>
        Se podrá comunicar con nuestro Oficial de Bases de Datos al correo
        electrónico el indicado en la identificación del presente documento.
      </p>
      <p>
        <b>Período de vigencia de los datos</b>
      </p>
      <p>
        Los datos personales de los titulares de los datos personales estarán
        vigentes durante el plazo necesario para cumplir los usos y finalidades
        correspondientes.
      </p>
      <p>
        <b>Jurisdicción y Ley Aplicable</b>
      </p>
      <p>
        Este acuerdo estará regido en todos sus puntos por las leyes vigentes en
        la República de Colombia y se regirá por el idioma castellano.
      </p>
      <p>
        <b>Domicilio</b>
      </p>
      <p>
        Se fija como domicilio de Bestvoiper el indicado en la identificación
        del presente documento.
      </p>
      <p>
        <b>Inquietudes</b>
      </p>
      <p>
        Ante cualquier duda podrá comunicarse con el correo indicado en la
        identificación del presente documento.
      </p>
    </div>
  );
}
