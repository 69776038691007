import React, { useState } from "react";
import "../App.css";
import about from "../elementos/about.json";
import Slider from "react-slick";
import intro from "../elementos/intro.mp4";
import TITULOIP from "../elementos/TITULO_IPTRUNK.svg";
import aplicacion1 from "../elementos/IPTRUNK1.svg";
import aplicacion2 from "../elementos/IPTRUNK2.svg";
import aplicacion3 from "../elementos/IPTRUNK3.svg";
import aplicacion4 from "../elementos/IPTRUNK4.svg";
import aplicacion5 from "../elementos/IPTRUNK5.svg";
import Contacto from "./Contacto.js";
import animation1 from "../elementos/ip1.json";
import animation2 from "../elementos/marketing.json";
import lottie from "lottie-web";
import Navegacion from "./Navegacion.js";
import { FaArrowRight, FaArrowLeft,FaShoppingCart } from "react-icons/fa";
import RedesSociales from "./RedesSociales.js"
export default function BestIptrunk() {
  let animationContainer = React.createRef();
  let animationContainer2 = React.createRef();

  React.useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      animationData: animation1,
    });
    anim.setSpeed(1);
    const anim2 = lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animation2,
    });
    anim2.setSpeed(1);
    const script = document.createElement("script");

    script.src = "https://checkout.epayco.co/checkout.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="bigContainer">
      <Navegacion />
     
      <div className="containerCall">
        <div className="videoCallcenter">
          <div className="conjunto_circle">
            <div className="circle"></div>
            <div className="circle2"></div>
            <div className="circle3"></div>
          </div>
          <video controls autoPlay>
            <source src={intro} type="video/mp4" />
          </video>
        </div>
        <div className="tituloIP">
          <img src={TITULOIP} />
        </div>
      </div>
     <div className="cont">
      <div className="containerIP">
        <div className="containerI">
          <div className="imagenIP" ref={animationContainer} />
          <p className="contenidoIP">
           
            Servicio de troncales IP que permite conectividad a través del
            protocolo SIP a cualquier destino en el mundo, con la más alta
            calidad y confiabilidad. Contamos con sistema automático de backend,
            para garantizar una operación con disponibilidad del 99,9%.
          </p>
        </div>
      </div>

      <div className="containerIP2">
        <div className="containerI">
          <p className="contenidoIP">
          
            Soportamos tráfico masivo y retail para las necesidades de PBXs,
            campañas corporativas, campañas de TeleMarketing y CallCenter.
            Nuestros planes van desde un canal hasta cientos y pueden ser
            ilimitados o con cobros por minuto, de acuerdo a los requerimientos
            de su negocio.
          </p>
          <div className="imagenIP" ref={animationContainer2} />
        </div>
      </div>
      </div>

      <p className="tituloCaracteristicas">CARACTERISTICAS</p>
      <div className="agregado">
        <div className="imagenFuncionalidad3">
          <img src={aplicacion1} />
          <img src={aplicacion2} />
          <img src={aplicacion3} />
          <img src={aplicacion4} />
          <img src={aplicacion5} />
        </div>
      </div>
      <p className="adquiere">ADQUIERE BESTIPTRUNK AHORA MISMO</p>
      <div className="botonPago">

     
      <form className="checkout">
        <div className="inlineForm">
     
        <script
            src="https://checkout.epayco.co/checkout.js"
            class="epayco-button"
            data-epayco-key="4b3a43650f5eebfc4f4e306a400b069c"
            data-epayco-amount="300000"
            data-epayco-name="BestIpTrunk"
            data-epayco-description="BestIpTrunk"
            data-epayco-currency="cop"
            data-epayco-country="co"
            data-epayco-test="false"
            data-epayco-button="https://multimedia.epayco.co/epayco-landing/btns/boton-epayco-fondo-oscuro1.png
            "
           
            data-epayco-external="false"
            data-epayco-response="http://www.bestvoiper.com.co/feedback"
            data-epayco-confirmation="http://www.bestvoiper.com.co/feedback"
            data-epayco-methodconfirmation="get">
        </script>
        <FaShoppingCart className="iconS"/>
        </div>
    </form>
      </div>
      <Contacto name="bestiptrunk"/>
    </div>
  );
}
