import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDiagramProject,
  faCircleInfo,
  faUser,
  faAnglesDown,
  faHome,
  faShoppingCart,
  faPowerOff,
  faCookieBite
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../context/authContext";
import Menu from "./Menu.js"
export default function 
() {
  const { user, logOut, loading, current } = useAuth();
  const handleLogOut = async () => {
    await logOut();
  };
  const [state, setState] = useState(false)
  const handle = () =>{
    setState(!state)
    toogle()
  }
  const toogle = () =>{
    setState(!state)
  }  
    
  
 
  return (
    <div>
      <div id="men">
     
      </div>
        <div className="menu-principal">
        <div  onClick={handle} className="contenedorMenuPrincipal">
          <FontAwesomeIcon   icon={faBars} className="hamburguer"/>
            {state ? <Menu/> : null}
          
        </div>
      </div>

      <div className="menu-lateral">
        <div className="contenedorMenuLateral">
        <Link to="/">
        <FontAwesomeIcon title="Inicio" icon={faHome} className="icono"/>
        </Link>
        <Link to="/about">
        <FontAwesomeIcon title="Acerca de Nosotros" icon={faCircleInfo} className="icono"/>
        </Link>
        <Link to="/productos">
        <FontAwesomeIcon title="Productos" icon={faShoppingCart} className="icono"/>
        </Link>
       {current ? (
         <div onClick={handleLogOut} > 
         <FontAwesomeIcon title="Cerrar Sesión" icon={faPowerOff} className="icono"/>
        </div>
        
       ) :
       <Link to="/login"> 
       <FontAwesomeIcon title="Login" icon={faUser} className="icono"/>
      </Link>
       }
       <Link to="/politicas"> 
       <FontAwesomeIcon title="Cookies" icon={faCookieBite} className="icono"/>
      </Link>
        </div>
      </div>
    </div>
  )
}
